import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ButtonIcon from "../../icon/buttonIcon"

const HeritageMoreBranding = () => {
  return (
    <section className="home-cs-listing branding-listing">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              More Branding Projects
            </h2>
          </Col>
        </Row>
        <Row className="wrapper-main">          
            <Col lg={4} className="mt-md-0 mt-3">
                <Link to="/branding/blue-mermaid-events/" className="home-cs-link first">
                <div className="hcs-img">
                    <StaticImage
                    src="../../images/branding/blue-mermaid-events/blue-mermaid-events-brand-listing-img.png"
                    alt="Blue Mermaid Events Branding Project | WowMakers"
                    placeholder="blurred"
                    width={507}
                    height={354}
                    className="img-fluid"
                    />
                </div>
                <div className="hcs-content branding">
                    <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Hospitality & Tourism</span>
                    <div className="cs-header">
                    <h3>
                        Blue Mermaid Events
                    </h3>
                    </div>
                    <div className="btn wowaction-btn">
                    <ButtonIcon />
                    <span>Read More</span>
                    </div>
                </div>
                </Link>
            </Col>
            <Col lg={4} className="mt-lg-0 mt-3">
                <Link to="/branding/square-projects/" className="home-cs-link middle">
                <div className="hcs-img">
                    <StaticImage
                    src="../../images/branding/square-projects/square-projects-listing-image.png"
                    alt="Square Projects Branding Project | WowMakers"
                    placeholder="blurred"
                    width={507}
                    height={354}
                    className="img-fluid"
                    />
                </div>
                <div className="hcs-content branding">
                    <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Construction</span>
                    <div className="cs-header">
                    <h3>
                        Square Projects
                    </h3>
                    </div>
                    <div className="btn wowaction-btn">
                    <ButtonIcon />
                    <span>Read More</span>
                    </div>
                </div>
                </Link>
            </Col>
            <Col lg={4} className="mt-lg-0 mt-3">
                <Link to="/branding/theyyam-trails/" className="home-cs-link last">
                  <div className="hcs-img">
                  <StaticImage
                      src="../../images/branding/theyyam-trails/theyyam-trails-listing-image.png"
                      alt="The Heritage 1866 Branding Project | WowMakers"
                      placeholder="blurred"
                      width={507}
                      height={354}
                      className="img-fluid"
                  />
                  </div>
                  <div className="hcs-content branding">
                  <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Hospitality & Tourism</span>
                  <div className="cs-header">
                      <h3>
                        Theyyam Trails
                      </h3>
                  </div>
                  <div className="btn wowaction-btn">
                      <ButtonIcon />
                      <span>Read More</span>
                  </div>
                  </div>
                </Link>
            </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HeritageMoreBranding
