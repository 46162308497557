import React from "react"
import SeoHead from "../../components/global/seoHead"

import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import brandingFeaturedImage from "../../../static/branding/the-heritage-1866/heritage-1888-featured-image.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/heritage.scss"
import HeritageMain from "../../components/branding/the-heritage-1866/heritageMain"
import HeritageFullwidth from "../../components/branding/the-heritage-1866/heritageFullWidth"
import HeritageMoreBranding from "../../components/branding/the-heritage-1866/heritageMoreBranding"


export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="The Heritage 1866: Branding Timeless Luxury | WowMakers"
            description="Uncover the elegance in The Heritage 1866's brand identity. We blended rich history with contemporary sophistication."
        />
    </>
)

const TheHeritage1866Branding = () => {
    return (
        <Layout>
            <Seo
                bid="branding-heritage"
                bclass="case-studies-page branding"
                >
            </Seo>
            <ScrollIndicationHeightZero />
            <HeritageMain />
            <HeritageFullwidth />
            <HeritageMoreBranding />
        </Layout>
    )
}

export default TheHeritage1866Branding
